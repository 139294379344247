export const SERVER_URL = process.env.REACT_APP_SERVER_URL as string;
export const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL as string;
export const REGION = process.env.REACT_APP_REGION as string;
export const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID as string;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;
export const IDENTITY_POOL_ID = process.env.REACT_APP_IDENTITY_POOL_ID as string;
export const IDENTITY_POOL_LOGIN = process.env.REACT_APP_IDENTITY_POOL_LOGIN as string;
export const STRIPE_PK = process.env.REACT_APP_STRIPE_PK as string;
export const PURCHASE_PRICE = process.env.REACT_APP_PURCHASE_PRICE as string;
export const VIDEO_BUCKET = process.env.REACT_APP_VIDEO_BUCKET as string;
export const LANDING_URL = process.env.REACT_APP_LANDING_URL as string;
export const GOOGLE_ANALYTICS_TOKEN = process.env.REACT_APP_GOOGLE_ANALYTICS_TOKEN as string;